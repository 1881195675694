<template>
  <WebHeader/>
  <div class="g-container">
    <div class="new-create-article">
      <a-button @click="clickName(0)">写文章</a-button>
    </div>
    <div class="my-articles">
      <div v-for="myArticle in myArticles" :key="myArticle.article_id" style='margin-bottom:20px;'>
        <a class="my-article-name" @click="clickName(myArticle.article_id)">{{ myArticle.article_name }}</a>
      </div>
    </div>
    <WebFooter/>
  </div>
</template>

<script>
import WebHeader from '@/components/WebHeader.vue'
import WebFooter from '@/components/WebFooter.vue'
import {ref} from 'vue'
import articleApi from '@/api/article.js'
import {onMounted} from 'vue'
import {useRouter} from 'vue-router'

export default {
  name: 'MyArticles',
  components: {
    WebHeader,
    WebFooter
  },
  setup() {
    const myArticles = ref('')
    const load = () => {
      articleApi.getMyArticlesInfor().then((res) => {
        if (res['resCode'] === 200) {
          myArticles.value = res['resMsg']['data']
        } else {
          router.push({name: 'UserLogin', params: {name: 'MyArticles'}})
        }
      })
    }
    const router = useRouter()
    const clickName = (article_id) => {
      if (article_id === 0) {
        router.push({name: 'EditArticle'})
        return
      }
      router.push({name: 'MyArticle', params: {articleId: article_id}})
    }
    onMounted(() => {
      load()
    })
    return {
      clickName,
      myArticles
    }
  }
}
</script>
<style scoped lang="scss">
.my-articles {
  color: #000;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  .my-article-name {
    font-size: 24px;
    margin-right: 100px;
    font-weight: bolder;
    color: #2c3e50;
  }

  .my-article-name:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.new-create-article {
  text-align: right;
  margin-right: 20px;
}
</style>
